.wham-connect {
  margin: 1em;

  &-header {
    padding: 0.5rem 1rem;
  }

  &__form-wrapper {
    margin-top: 2em;
  }

  p {
    margin: 1em 0;
  }

  input {
    margin-right: 1em;
  }

  h5 {
    margin: 1em 0;
  }

  .qr-wrapper {
    margin: 1em 2em;
  }

}


.is-loading {
  position: relative;
  margin-right: 30px;
}

.is-loading::after {
  content: " ";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.qr-view {
  display: flex;
  padding: 2em;

  background-color: white;

  .qr-instructions {
    padding-right: 2rem;


    p {
      margin-top: 1.2rem;
      font-size: 2.3rem;
      color: #474747;
    }

    li {
      color: #474747;
      font-size: 1.8rem;
      margin: 1em;
    }

  }

  .qr-wrapper {
    position: relative;
  }
  .qr-chat-icon {
    position: absolute;
    width: 40px;
    top: 123px;
    left: 122px;

  }
}

